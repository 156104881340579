export function encodeEmailToAnchor(email, label) {
    if (typeof email !== 'string') {
        throw new TypeError('Email must be a string.');
    }

    const encodeChar = (char) => `&#${char.charCodeAt(0)};`;

    const encodedHref = 'mailto:' + [...email].map(encodeChar).join('');

    return `<a target="_blank" href="${encodedHref}">${label}</a>`;
}
